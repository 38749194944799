<template>
    <div class="payment-wizard text-white"
        style="align-content: center; background-color: #292D7D; min-height: 100vh; padding: 20px;">
        <div class="container text-center" v-if="productNotFound">
            <h1>
                <i class="fa-solid fa-xmark"></i>
            </h1>
            <h3 class="fw-bold">Impossibile trovare il prodotto.</h3>
            <router-link to="/">
                <button class="btn btn-custom">Torna all'App</button>
            </router-link>
        </div>
        <div v-if="selectedProduct" class="container-fluid">
            <div class="row">
                <!-- Left column with steps -->
                <div class="col-md-6 p-md-5">
                    <div class="step-container">
                        <!-- Step 1 -->
                        <div v-if="showVariationStep" class="step" :class="{ active: step === 1 }">
                            <div class="step-header">
                                <div class="step-circle">1</div>
                                <h2 class="step-title">SELEZIONA IL PIANO</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 1" class="step-content">
                                    <div class="duration-grid">
                                        <div v-for="variation in selectedProduct.variations"
                                            :key="variation.variationName" @click="selectVariation(variation)"
                                            :class="['duration-option', { selected: selectedVariationName === variation.variationName }]">
                                            <div class="duration-title">{{ variation.variationName }}</div>
                                            <div class="duration-price">{{ variation.price }}€</div>
                                            <div v-if="selectedVariationName === variation.variationName"
                                                class="checkmark">&#10004;</div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button @click="nextStep" class="btn btn-custom mt-4">Avanti</button>
                                    </div>
                                </div>
                            </transition>
                        </div>

                        <!-- Step 2 -->
                        <div v-if="!userLogged" class="step" :class="{ active: step === 2 }">
                            <div class="step-header">
                                <div class="step-circle">{{ showVariationStep? '2':'1' }}</div>
                                <h2 class="step-title">I TUOI DATI</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 2" class="step-content">
                                    <form @submit.prevent="nextStep">
                                        <router-link :to="{ name: 'login', query: { productId: selectedProductId } }">
                                            <div class="btn white-container">
                                                Hai già un account? Accedi
                                            </div>
                                        </router-link>

                                        <div @click="toggleSection('personalInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2">
                                            <h4>Informazioni Personali</h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>

                                        <div v-show="!collapsedSections.personalInfo">
                                            <div class="form-group">
                                                <input v-model="user.personalInfo.firstName" type="text"
                                                    placeholder="Nome" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.personalInfo.lastName" type="text"
                                                    placeholder="Cognome" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.personalInfo.dateOfBirth" type="date"
                                                    placeholder="Data di nascita" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.personalInfo.placeOfBirth" type="text"
                                                    placeholder="Luogo di nascita" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.personalInfo.taxCode" type="text"
                                                    placeholder="Codice Fiscale" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <GenderSelect v-model="user.personalInfo.gender"></GenderSelect>
                                            </div>
                                        </div>
                                        <div @click="toggleSection('contactInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2 mt-5">
                                            <h4>Recapiti</h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>
                                        <div v-show="!collapsedSections.contactInfo">
                                            <div class="form-group">
                                                <input v-model="user.contactInfo.address" type="text"
                                                    placeholder="Indirizzo" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.contactInfo.city" type="text" placeholder="Città"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.contactInfo.postalCode" type="text"
                                                    placeholder="CAP" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.contactInfo.province" type="text"
                                                    placeholder="Provincia" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.contactInfo.phoneNumber" type="text"
                                                    placeholder="Telefono" class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.contactInfo.mobileNumber" type="text"
                                                    placeholder="Altro telefono" class="form-control" required />
                                            </div>
                                        </div>
                                        <div @click="toggleSection('accountInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2 mt-5">
                                            <h4>Crea un account</h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>
                                        <div v-show="!collapsedSections.accountInfo">
                                            <div class="form-group">
                                                <input v-model="user.email" type="email" placeholder="Email"
                                                    class="form-control" @blur="checkEmailExists"
                                                    :class="{ 'is-invalid': emailError }" required />
                                                <span v-if="emailError" class="text-danger">{{ emailError }}</span>
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.password" type="password" placeholder="Password"
                                                    class="form-control" required />
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-custom mt-4">Avanti</button>
                                        </div>
                                    </form>
                                </div>
                            </transition>
                        </div>

                        <!-- Step 3 -->
                        <div class="step" :class="{ active: step === 3 }">
                            <div class="step-header">
                                <div class="step-circle">{{ (showVariationStep&&!userLogged)? '3':'2' }}</div>
                                <h2 class="step-title">PAGAMENTO</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 3" class="step-content">
                                    <!-- Payment Request Button (e.g. Apple Pay) -->
                                    <div v-if="paymentRequestButton">
                                        <div ref="paymentRequestButton"></div>
                                    </div>

                                    <!-- Stripe Credit Card Payment -->
                                    <div v-else>
                                        <h4>Carta di credito</h4>
                                        <div id="card-element" class="form-control mb-3"></div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- Right column with overview -->
                <div class="col-md-6" style="border-left: 3px solid; border-color: white;">
                    <div class="overview p-5">
                        <h2 class="fw-bold">PANORAMICA</h2>

                        <p>Stai acquistando <strong class="highlight">{{ selectedProduct.name }}</strong></p>

                        <!-- Variation Overview -->
                        <div v-if="selectedVariationName">
                            <div class="row">
                                <div class="col">
                                    <h4 class="mt-5 fw-bold">QUOTA MENSILE</h4>
                                </div>
                                <div class="col">
                                    <h4 class="mt-5 fw-bold text-end">{{ (selectedVariationPrice - discountAmount).toFixed(2) || 'N/A' }}€</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <ul class="list-unstyled">
                                        <li>
                                            Durata:
                                        </li>
                                        <li>
                                            Quota di abbonamento:
                                        </li>
                                        <li>
                                            Rinnovo:
                                        </li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <ul class="list-unstyled text-end">
                                        <li>
                                            <span class="highlight">{{ selectedVariationName || 'N/A' }}</span>
                                        </li>
                                        <li>
                                            <span class="highlight">{{ (finalPrice - discountAmount).toFixed(2) || 'N/A' }}€</span>
                                        </li>
                                        <li>
                                            <span class="highlight">fra {{ renewalPeriod || 'N/A' }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <!-- Product Overview -->
                        <div v-if="!showVariationStep">
                            <div class="row">
                                <div class="col">
                                    <h4 class="mt-5 fw-bold">TOTALE</h4>
                                </div>
                                <div class="col">
                                    <h4 class="mt-5 fw-bold text-end">{{ (selectedVariationPrice - discountAmount).toFixed(2) || 'N/A' }}€</h4>
                                </div>
                            </div>

                        </div>

                        <p class="terms">
                            Accetto i termini e le <a href="#">condizioni generali di contratto</a>.<br>
                            Confermo di aver preso visione dell'informativa relativa al <a href="#">diritto di
                                recesso</a> e dell'informativa sulla <a href="#">Privacy</a>.
                        </p>
                        <div v-if="selectedVariationName || !showVariationStep" class="mt-3">
                            <div class="input-group mb-3">
                                <input type="text" v-model="discountCode" class="form-control discount"
                                    placeholder="CODICE SCONTO" aria-label="Codice Sconto"
                                    aria-describedby="button-addon2">
                                <button class="btn btn-danger rounded-0" type="button"
                                    @click="applyDiscount">APPLICA</button>
                            </div>
                        </div>
                        <div class="text-center">
                            <button :disabled="!isPaymentReady" @click="handleCardPayment"
                                class="btn btn-custom complete-payment-btn text-nowrap">COMPLETA IL PAGAMENTO</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import GenderSelect from '@/components/GenderSelect.vue';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

export default {
    components: {
        GenderSelect
    },
    setup() {
        const route = useRoute();

        onMounted(() => {
            console.log("Route object after mount:", route);
            console.log("Product ID from URL after mount:", route.query?.productId);
        });

        return {};
    },
    data() {
        return {
            userLogged: false,
            userEmail: null,
            userToken: null,

            step: 1,  // Step iniziale
            selectedVariationName: null,  // Variazione selezionata
            selectedProductId: null,  // Imposta l'id del prodotto specifico qui
            selectedProduct: null,

            productNotFound: false,

            hasVariations: false,  // Indica se il prodotto ha variazioni
            showVariationStep: false,  // Controlla se lo step delle variazioni deve essere mostrato

            collapsedSections: {
                personalInfo: false,
                contactInfo: true,
                accountInfo: true,
            },
            user: {
                email: '',
                password: '',
                personalInfo: {
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    placeOfBirth: '',
                    taxCode: '',
                    gender: ''
                },
                contactInfo: {
                    address: '',
                    city: '',
                    postalCode: '',
                    province: '',
                    phoneNumber: '',
                    mobileNumber: ''
                }
            },
            stripe: null,
            elements: null,
            cardElement: null,
            paymentRequestButton: null,
            paymentRequest: null,
            isPaymentReady: false,

            discountCode: '',  // Codice sconto inserito dall'utente
            discountAmount: 0,  // Importo dello sconto
            discountError: null,  // Messaggio di errore per il codice sconto
            finalPrice: 0,  // Prezzo finale dopo lo sconto

            emailError: null
        };
    },
    async created() {
        try {
            // Inizializza Stripe
            this.stripe = await loadStripe('pk_live_51OtUFJEHkZfMednN6BucyYkJOmLNU59eic1YVCrbEjbKSSmWJwOALrY66BipFuxyjCzlJSXAly15TqU8YO6LPCm400tp8TIrsz');
            if (!this.stripe) {
                throw new Error('Stripe failed to initialize');
            }

            // Verifica se l'utente è loggato
            const email = localStorage.getItem('userEmail');
            const token = localStorage.getItem('userToken');

            if (email && token) {
                this.userLogged = true;
                this.userEmail = email;
                this.userToken = token;
            }

            const productIdFromUrl = this.$route.query?.productId;

            console.log(productIdFromUrl)

            let productResponse;

            if (productIdFromUrl) {
                // Carica il prodotto specifico tramite il productId dall'URL
                productResponse = await fetch(`https://elitefitness.utopialabs.it/api/products/${productIdFromUrl}`);
                if (productResponse.ok) {
                    const product = await productResponse.json();
                    this.selectedProduct = product;
                    this.selectedProductId = product.id;
                } else {
                    // Se la richiesta non va a buon fine, imposta productNotFound su true
                    this.productNotFound = true;
                }
            } else {
                // Continua con il funzionamento attuale
                productResponse = await fetch('https://elitefitness.utopialabs.it/api/products');
                if (productResponse.ok) {
                    const products = await productResponse.json();
                    this.selectedProduct = products.find((p) => p.name.includes("Body Building"));
                    if(this.selectedProduct)
                        this.selectedProductId = this.selectedProduct.id;
                    else this.productNotFound = true;
                }
            }

            // Controlla se il prodotto ha variazioni
            if ((this.selectedProduct.type === 'GYM' || this.selectedProduct.type === 'COURSE') && this.selectedProduct.variations && this.selectedProduct.variations.length > 0) {
                this.hasVariations = true;
                this.showVariationStep = true;
            } else {
                // Non ci sono variazioni o il prodotto non è di tipo GYM o COURSE
                this.hasVariations = false;
                this.showVariationStep = false;
                this.finalPrice = this.selectedProduct.price;  // Usa il prezzo del prodotto
                this.step = 2; // Salta direttamente allo step successivo
            }

        } catch (error) {
            console.error('Error initializing PaymentComponent:', error);
        }
    },
    computed: {
        selectedVariationPrice() {
            if (this.selectedProduct?.variations) {
                const variation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
                return variation ? variation.price : null;
            }
            else{
                return this.selectedProduct.price;
            }
        },
        renewalPeriod() {
            const variation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
            return variation ? variation.attributes.duration + ' giorni' : null;
        }
    },
    methods: {
        clearUserData() {
            localStorage.removeItem('user');
        },
        saveUserData() {
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        async checkEmailExists() {
            try {
                if (!this.user.email) {
                    this.emailError = 'Inserisci un\'email valida.';
                    return;
                }

                const response = await fetch(`https://elitefitness.utopialabs.it/api/users/exists?email=${encodeURIComponent(this.user.email)}`);

                if (!response.ok) {
                    throw new Error('Errore nella verifica dell\'email');
                }

                const result = await response.json();

                // Verifica il risultato: se l'email esiste, mostra un errore
                if (result.exists) {
                    this.emailError = 'Questa email è già registrata.';
                } else {
                    this.emailError = null; // Nessun errore, email non esistente
                }
            } catch (error) {
                console.error('Errore nella verifica dell\'email:', error);
                this.emailError = 'Impossibile verificare l\'email. Riprova più tardi.';
            }
        },
        async applyDiscount() {
            if (!this.discountCode.trim()) {
                this.discountError = 'Inserisci un codice sconto valido.';
                return;
            }

            try {
                // Effettua la chiamata API per verificare il codice sconto
                const response = await fetch(`https://elitefitness.utopialabs.it/api/promo/${this.discountCode}`);

                if (!response.ok) {
                    throw new Error('Codice sconto non valido.');
                }

                const promo = await response.json();

                // Verifica se il codice sconto è attivo e valido
                const now = Date.now();
                if (!promo.active || now < promo.startDate || now > promo.endDate || promo.currentUsage >= promo.usageLimit) {
                    this.discountError = 'Codice sconto non valido o scaduto.';
                    return;
                }

                // Calcola lo sconto
                if (promo.percentage) {
                    this.discountAmount = (promo.discountAmount / 100) * this.selectedVariationPrice;
                } else {
                    this.discountAmount = promo.discountAmount;
                }

                // Aggiorna il prezzo finale
                this.finalPrice = this.selectedVariationPrice - this.discountAmount;
                this.discountError = null;  // Rimuovi eventuali errori
            } catch (error) {
                this.discountError = error.message;
                this.discountAmount = 0;  // Reset dello sconto in caso di errore
                this.finalPrice = this.selectedVariationPrice;  // Reset del prezzo finale
            }
        },
        toggleSection(section) {
            Object.keys(this.collapsedSections).forEach((key) => {
                this.collapsedSections[key] = true;
            });

            this.collapsedSections[section] = !this.collapsedSections[section];
        },
        selectVariation(variation) {
            this.selectedVariationName = variation.variationName;
            this.finalPrice = variation.price;
        },
        nextStep() {
            if (this.step === 2 && (this.emailError || !this.user.email || !this.user.password)) {
                alert("Correggi gli errori prima di proseguire.");
                return;
            }

            if (this.step === 1 && !this.selectedVariationName) {
                alert("Seleziona un piano per continuare.");
                return;
            }
            if (this.step === 2 && !this.isUserDataValid()) {
                alert("Please fill out all required fields.");
                return;
            }

            this.saveUserData();

            this.step += 1;
            if(this.step === 2 && this.userLogged === true)
                this.step += 1;

            // Monta Stripe Element allo step 3
            if (this.step === 3) {
                this.initializeStripeElements();
                this.isPaymentReady = false;  // Disabilita il pulsante di pagamento fino a quando i dati della carta non sono pronti
            }
        },
        initializeStripeElements() {
            console.log("initializeStripeElements")
            this.$nextTick(() => {
                if (!this.stripe) {
                    console.error("Stripe is not initialized yet.");
                    return;
                }

                if (!this.elements) {
                    this.elements = this.stripe.elements();
                }

                if (!this.cardElement) {
                    // Definisci lo stile per l'elemento della carta
                    const style = {
                        base: {
                            color: 'white',            // Testo bianco
                            fontSize: '20px',          // Testo più grande
                            '::placeholder': {
                                color: 'rgba(255, 255, 255, 0.7)',  // Placeholder bianco, ma più trasparente
                            },
                            textAlign: 'center',       // Testo centrato
                            fontFamily: 'Montserrat, sans-serif', // Imposta il font
                            iconColor: 'white',        // Colore delle icone
                        },
                        invalid: {
                            color: '#ff4d4f',           // Colore rosso per gli input non validi
                            iconColor: '#ff4d4f',
                        },
                    };

                    // Crea e monta l'elemento della carta con lo stile
                    this.cardElement = this.elements.create('card', { style });
                    this.cardElement.mount('#card-element');
                }

                // Abilita il pulsante di pagamento dopo che i dati della carta sono pronti
                this.isPaymentReady = true;
            });
        },
        async handleCardPayment() {
            try {
                if (!this.stripe || !this.cardElement) {
                    alert("Stripe or card element is not ready.");
                    return;
                }

                const selectedVariation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
                if (!selectedVariation) {
                    console.error("Selected variation not found");
                    return;
                }

                const paymentRequest = {
                    productId: this.selectedProductId,
                    variationName: this.selectedVariationName,
                    newUser: this.userLogged?null:this.user,  
                    promoCode: this.discountCode,
                    registerUser: !this.userLogged,
                    email: this.userEmail,
                    token: this.userToken
                };

                const response = await fetch('https://elitefitness.utopialabs.it/api/payment/create', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(paymentRequest)
                });

                if (!response.ok) {
                    throw new Error('Failed to create payment intent');
                }

                const { client_secret } = await response.json();

                const { error, paymentIntent } = await this.stripe.confirmCardPayment(client_secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {
                            name: this.user.personalInfo.firstName + ' ' + this.user.personalInfo.lastName,
                            email: this.user.email
                        }
                    }
                });

                if (error) {
                    alert(error.message);
                } else if (paymentIntent.status === 'succeeded') {
                    //this.clearUserData();
                    this.$router.push('/success');
                }
            } catch (error) {
                console.error(error);
                alert('An error occurred during payment.');
            }
        },
        isUserDataValid() {
            return this.user.email && this.user.password && this.user.personalInfo.firstName && this.user.personalInfo.lastName;
        }
    }
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

h2,
h3,
h4 {
    font-family: 'Montserrat', sans-serif;
}

.step-header {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: left;
    margin-bottom: 40px;
}

.step-circle {
    font-family: 'Montserrat', sans-serif;
    width: 50px;
    height: 50px;
    background-color: white;
    color: #e83539;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.step-title {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

.step-content {
    margin-bottom: 40px;
    margin-left: 0;
    /* Nessun margine a sinistra su dispositivi piccoli */
}

@media (min-width: 768px) {
    .step-content {
        margin-left: 65px;
        /* Applica margin-left solo su tablet e dispositivi più grandi */
    }
}


.duration-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width: 767px) {
    .duration-grid {
        grid-template-columns: 1fr;
    }
}

.duration-option {
    font-family: 'Montserrat', sans-serif;
    background-color: #2e348c;
    border: 2px solid white;
    padding: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    position: relative;
}

/** Mobile */
@media (max-width: 767px) {
    .duration-option {
        width: 100%;
        padding: 5px;
    }
}

.duration-option.selected {
    border-color: #e83539;
    background-color: #292D7D;
}

.duration-title {
    font-size: 1.2rem;
    /* Font-size su dispositivi mobili */
    font-weight: 600;
}

@media (min-width: 768px) {
    .duration-title {
        font-size: 1.5rem;
    }
}

.duration-price {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 10px;
}

.checkmark {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: #e83539;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.overview {
    padding: 20px;
}

h4 {
    margin: 0px;
    padding: 0px;
}

h2,
h3,
p,
ul {
    color: white;
}

ul {
    padding: 0;
}

.terms {
    font-size: 0.9rem;
    text-align: center;
    margin: 20px 0;
}

.terms a {
    color: white;
    text-decoration: underline;
}

.complete-payment-btn {
    background-color: #b2003b;
    width: 100%;
    max-width: 100% !important;
    margin: 20px auto;
    padding: 10px;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.complete-payment-btn:disabled {
    background-color: #e83539;
    cursor: not-allowed;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

.btn-custom:hover {
    background-color: #cc2e31;
}

.highlight {
    font-weight: 600;
    color: white;
    text-decoration: underline;
}

form input,
#card-element {
    color: black;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

form select {
    color: white;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

select::after {
    color: white;
}

.form-select {
    appearance: none;
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
    border-radius: 0px;
    width: 100%;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FFFFFF" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 5.646a.5.5 0 0 1 .708 0L8 11.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-size: 24px;
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.discount {
    color: rgb(19, 19, 19) !important;
    background-color: white;
    border-radius: 0px;
    height: 45px;
}

.white-container{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.207);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

.white-container:hover{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.352);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}
</style>