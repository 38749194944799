<template>
    <HeaderMenu />
    <div class="account-page text-white"
        style="align-content: center; background-color: #292D7D; min-height: 100vh; padding: 20px;">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>Account</h1>
            <h4 class="mb-4">Dettagli abbonamento</h4>
            <SubscriptionDetailsCard />
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
//import { useRoute } from 'vue-router';
import { onMounted } from 'vue';
import SubscriptionDetailsCard from '@/components/SubscriptionDetailsCard.vue'; 
import HeaderMenu from '@/components/HeaderMenu.vue'

export default {
    components: {
        SubscriptionDetailsCard,
        HeaderMenu
    },
    setup() {
        onMounted(() => {
            
        });

        return {};
    },
    data() {
        return {
        };
    },
    async created() {
        
    },
    computed: {
        
    },
    methods: {
        
    }
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

h2,
h3,
h4 {
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 767px) {
    .account-page {
        padding-top: 3rem !important;
        align-content: start !important;
    }
}

.step-header {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: left;
    margin-bottom: 40px;
}

.step-circle {
    font-family: 'Montserrat', sans-serif;
    width: 50px;
    height: 50px;
    background-color: white;
    color: #e83539;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.step-title {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

.step-content {
    margin-bottom: 40px;
    margin-left: 0;
    /* Nessun margine a sinistra su dispositivi piccoli */
}

@media (min-width: 768px) {
    .step-content {
        margin-left: 65px;
        /* Applica margin-left solo su tablet e dispositivi più grandi */
    }
}


.duration-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width: 767px) {
    .duration-grid {
        grid-template-columns: 1fr;
    }
}

.duration-option {
    font-family: 'Montserrat', sans-serif;
    background-color: #2e348c;
    border: 2px solid white;
    padding: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    position: relative;
}

/** Mobile */
@media (max-width: 767px) {
    .duration-option {
        width: 100%;
        padding: 5px;
    }
}

.duration-option.selected {
    border-color: #e83539;
    background-color: #292D7D;
}

.duration-title {
    font-size: 1.2rem;
    /* Font-size su dispositivi mobili */
    font-weight: 600;
}

@media (min-width: 768px) {
    .duration-title {
        font-size: 1.5rem;
    }
}

.duration-price {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 10px;
}

.checkmark {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: #e83539;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.overview {
    padding: 20px;
}

h4 {
    margin: 0px;
    padding: 0px;
}

h1 {
    font-weight: 700;
}

h2,
h3,
p,
ul {
    color: white;
}

ul {
    padding: 0;
}

.terms {
    font-size: 0.9rem;
    text-align: center;
    margin: 20px 0;
}

.terms a {
    color: white;
    text-decoration: underline;
}

.complete-payment-btn {
    background-color: #b2003b;
    width: 100%;
    max-width: 100% !important;
    margin: 20px auto;
    padding: 10px;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.complete-payment-btn:disabled {
    background-color: #e83539;
    cursor: not-allowed;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

.btn-custom:hover {
    background-color: #cc2e31;
}

.highlight {
    font-weight: 600;
    color: white;
    text-decoration: underline;
}

form input,
#card-element {
    color: black;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

form select {
    color: white;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

select::after {
    color: white;
}

.form-select {
    appearance: none;
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
    border-radius: 0px;
    width: 100%;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FFFFFF" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 5.646a.5.5 0 0 1 .708 0L8 11.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-size: 24px;
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.discount {
    color: rgb(19, 19, 19) !important;
    background-color: white;
    border-radius: 0px;
    height: 45px;
}

.white-container{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.207);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

.white-container:hover{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.352);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}


</style>