import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router';
import PaymentComponent from '@/components/PaymentComponent.vue';
import SuccessPage from '@/components/SuccessPage.vue';
import LoginPage from '@/components/LoginPage.vue';
import PasswordReset from '@/components/PasswordReset.vue';
import AccountVerification from '@/components/AccountVerification.vue';
import AccountPage from '@/components/AccountPage.vue';
import RegistrationPage from '@/components/RegistrationPage.vue';
import RegistrationComplete from '@/components/RegistrationComplete.vue';

// Importa Bootstrap e Popper.js
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

// Aggiungi lo script del kit di Font Awesome
const fontAwesomeScript = document.createElement('script');
fontAwesomeScript.src = 'https://kit.fontawesome.com/434cbde3b3.js';
fontAwesomeScript.crossOrigin = 'anonymous';
document.head.appendChild(fontAwesomeScript);

const routes = [
    { path: '/', redirect: '/account' },
    { 
        path: '/account', 
        name: 'account', 
        component: AccountPage,
        meta: { requiresAuth: true }
    },
    { path: '/checkout', name: 'checkout', component: PaymentComponent },
    { path: '/success', name:'success', component: SuccessPage },
    { path: '/login', name: 'login', component: LoginPage },
    { path: '/password-reset', name: 'password-reset', component: PasswordReset },
    { path: '/verify', name: 'verify', component: AccountVerification },
    { path: '/register', component: RegistrationPage },
    { path: '/registration-complete', component: RegistrationComplete }
];

// Crea il router
const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Aggiungi la guardia di navigazione
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('userToken')) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

const app = createApp(App);
app.use(router);
app.mount('#app');