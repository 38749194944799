<template>
    <div class="container subscription-container mt-4 pl-0">
        <div v-if="loading" class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-else-if="subscriptions.length > 0" class="subscriptions-list">
            <div v-for="(subscription, index) in subscriptions" :key="index" class="card mb-4">
                <div class="card-header text-white">
                    <h5 class="card-title mb-0 text-uppercase">{{ getSubscriptionTypeLabel(subscription.type) }}</h5>
                </div>
                <div class="card-body">
                    <p class="card-text">Stato: <strong>{{ subscription.active ? 'Attivo' : 'Non attivo' }}</strong></p>
                    <p class="card-text">Data di attivazione: <strong>{{ formatDate(subscription.activationDate) }}</strong></p>
                    <p class="card-text">Data di scadenza: <strong>{{ formatDate(subscription.expirationDate) }}</strong></p>
                    <p class="card-text">Modalità Stop&Go: <strong>{{ subscription.stopngo ? 'Attiva' : 'Non attiva' }}</strong></p>
                    
                    <h6 class="mt-4">Centri abilitati</h6>
                    <ul class="list-group">
                        <li v-for="center in subscription.centers" :key="center.id" class="list-group-item">
                            <i class="fa-regular fa-building" style="margin-right: 10px"></i> {{ center.name }}
                        </li>
                    </ul>
                    
                    <h6 class="mt-4">Servizi inclusi</h6>
                    <ul class="list-group">
                        <li v-for="service in subscription.services" :key="service.id" class="list-group-item">
                            <i :class="getServiceIcon(service.type)" style="margin-right: 10px"></i>
                            <span>{{ service.name }}</span>
                        </li>
                    </ul>
                    
                    <div v-if="subscription.payment" class="mt-4">
                        <h6>Dettagli Pagamento</h6>
                        <div class="payment-card">
                            <p class="card-text">Importo: <strong>{{ subscription.payment.partial }} €</strong></p>
                            <p class="card-text">Stato: <strong>{{ formatPaymentStatus(subscription.payment.status) }}</strong></p>
                            <p class="card-text">Data: <strong>{{ formatDate(subscription.payment.date) }}</strong></p>
                            <p class="card-text">Metodo: <strong>{{ formatPaymentMethod(subscription.payment.method) }}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="alert alert-nosub" role="alert">
            Nessun abbonamento attivo trovato. Vuoi sottoscriverne uno nuovo?
            <button @click="goToSubscriptionPage" class="btn btn-custom mt-2">Acquista un abbonamento</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            subscriptions: [],
            loading: true,
            error: null
        };
    },
    computed: {
        isSubscriptionValid() {
            return this.subscriptions.some(subscription => {
                const currentTime = new Date().getTime();
                return subscription.active || (subscription.expirationDate > currentTime);
            });
        }
    },
    async created() {
        console.log("Created")
        const bridgeToken = this.$route.query.bridgeToken;
        if (bridgeToken) {
            console.log("using bridge token")
            await this.swapBridgeToken(bridgeToken);
        } else {
            console.log("checking authentication")
            this.checkAuthentication();
        }
    },
    methods: {
        async swapBridgeToken(bridgeToken) {
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/swap', { "bridgeToken" : bridgeToken });
                const { token, expiresIn } = response.data;
                localStorage.setItem('userToken', token);
                localStorage.setItem('tokenExpiration', expiresIn);
                this.userLogged = true;
                this.userToken = token;
                this.$router.replace({ query: {} });
                await this.fetchSubscriptions();
            } catch (error) {
                console.error('Errore durante lo scambio del token:', error);
                this.error = "Errore di autenticazione. Verrai reindirizzato alla pagina di login.";
                setTimeout(() => this.$router.push('/login'), 3000);
            }
        },
        checkAuthentication() {
            const token = localStorage.getItem('userToken');
            const expiration = localStorage.getItem('tokenExpiration');
            if (token && expiration && new Date().getTime() < parseInt(expiration)) {
                this.userLogged = true;
                this.userToken = token;
                this.fetchSubscriptions();
            } else {
                localStorage.removeItem('userToken');
                localStorage.removeItem('tokenExpiration');
                this.error = "Sessione scaduta. Verrai reindirizzato alla pagina di login.";
                setTimeout(() => this.$router.push('/login'), 3000);
            }
        },
        getServiceIcon(type) {
            switch (type) {
                case 'GYM':
                    return 'fa-solid fa-dumbbell';
                case 'COURSE':
                    return 'fa-solid fa-person-running';
                default:
                    return 'fa-solid fa-circle-question';
            }
        },
        async fetchSubscriptions() {
            const token = localStorage.getItem('userToken');
            if (!token) {
                this.$router.push('/login');
                return;
            }

            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/subscriptions/allActive', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 401 || response.status >= 500) {
                    localStorage.removeItem('userToken');
                    this.$router.push('/login');
                    return;
                }
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                this.subscriptions = await response.json();
                
                // Fetch additional details for each subscription
                for (let subscription of this.subscriptions) {
                    if (subscription.orderId) {
                        subscription.payment = await this.fetchPayment(subscription.orderId);
                    }
                    subscription.centers = await this.fetchCenters(subscription.centers);
                    subscription.services = await this.fetchServices(subscription.services);
                }
            } catch (err) {
                this.error = 'Errore nel recupero dei dati degli abbonamenti';
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        async fetchPayment(orderId) {
            const token = localStorage.getItem('userToken');
            try {
                const response = await fetch(`https://elitefitness.utopialabs.it/api/payments/order/${orderId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Error fetching payment');
                }
                return await response.json();
            } catch (err) {
                console.error('Error fetching payment:', err);
                return null;
            }
        },
        async fetchCenters(centerIds) {
            const token = localStorage.getItem('userToken');
            let centers = [];
            for (const centerId of centerIds) {
                try {
                    const response = await fetch(`https://elitefitness.utopialabs.it/api/centers/${centerId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`Error fetching center with id ${centerId}`);
                    }
                    const center = await response.json();
                    centers.push(center);
                } catch (err) {
                    console.error(`Error fetching center ${centerId}:`, err);
                    centers.push({ id: centerId, name: `Centro ${centerId} (non disponibile)` });
                }
            }
            return centers;
        },
        async fetchServices(serviceIds) {
            const token = localStorage.getItem('userToken');
            let services = [];
            for (const serviceId of serviceIds) {
                try {
                    const response = await fetch(`https://elitefitness.utopialabs.it/api/services/${serviceId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`Error fetching service with id ${serviceId}`);
                    }
                    const service = await response.json();
                    services.push(service);
                } catch (err) {
                    console.error(`Error fetching service ${serviceId}:`, err);
                    services.push({ id: serviceId, name: `Servizio ${serviceId} (non disponibile)`, type: 'UNKNOWN' });
                }
            }
            return services;
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString('it-IT', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        },
        formatPaymentStatus(status) {
            switch (status) {
                case 'PENDING':
                    return 'In attesa';
                case 'PAID':
                    return 'Pagato';
                case 'PARTIALLY_PAID':
                    return 'Parzialmente pagato';
                case 'REFUNDED':
                    return 'Rimborsato';
                default:
                    return 'Sconosciuto';
            }
        },
        formatPaymentMethod(method) {
            switch (method) {
                case 'CREDIT_CARD':
                    return 'Carta di credito';
                case 'DEBIT_CARD':
                    return 'Carta di debito';
                case 'PAYPAL':
                    return 'PayPal';
                case 'BANK_TRANSFER':
                    return 'Bonifico bancario';
                case 'CASH':
                    return 'Contanti';
                case 'NONE':
                    return 'Nessuno';
                default:
                    return 'Sconosciuto';
            }
        },
        getSubscriptionTypeLabel(type) {
            switch (type) {
                case 'FITNESS_LOVER':
                    return 'Piano Fitness Lover';
                case 'TARGET_TRAINING':
                    return 'Piano Target Training';
                case 'PERSONAL_TRAINING':
                    return 'Piano Personal Training';
                default:
                    return '';
            }
        },
        getGymSubscription(type){
            return ['FITNESS_LOVER', 'TARGET_TRAINING', 'PERSONAL_TRAINING'].includes(type);
        },
        goToSubscriptionPage() {
            this.$router.push('/checkout');
        }
    },
    mounted() {
        this.fetchSubscriptions();
    }
};
</script>

<style>
body{
    font-family: 'Montserrat', sans-serif;
    color: white;
}

h1{
    font-family: 'Montserrat', sans-serif;
    color: white;
}

h6{
    color: white !important;
}

.card-body{
    font-family: 'Montserrat', sans-serif;
    background-color: #30348e;
    border-radius: 0px !important;
    color: white;
}

.card-text{
    color:white;
}

.card-header{
    font-family: 'Montserrat', sans-serif !important;
    border-radius: 0px !important;
    background-color: #e83539 !important;
}

.card{
    border: 2px solid #e83539 !important;
    border-radius: 0px !important;
}

.subscription-container{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.alert-nosub{
    font-family: 'Montserrat', sans-serif !important;
    border: 2px solid !important;
    border-radius: 0px !important;
    border-color: #e83539 !important;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif !important;
    background-color: #e83539 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    border-radius: 0 !important;
    font-size: 1rem !important;
}

.btn-custom:hover {
    background-color: #cc2e31 !important;
    color: white !important;
}

.list-group-item{
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    border: 0px !important;
    border-radius: 0px !important;
}

.payment-card{
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: 0px;
    border-radius: 0px;
    padding: 15px;
}

.subscriptions-list > .card:not(:last-child) {
    margin-bottom: 2rem;
}
</style>