<template>
    <div class="login-page">
        <div class="container">
            <a href="https://elitefitness.it">
                <img src="@/assets/logo.png" alt="Logo" class="logo">
            </a>
            <p>Accedi inserendo le tue credenziali.</p>
            <form class="m-2" @submit.prevent="login">
                <div class="form-group">
                    <input v-model="email" type="email" placeholder="Email" class="form-control" required />
                </div>
                <div class="form-group">
                    <input v-model="password" type="password" placeholder="Password" class="form-control" required />
                </div>
                <router-link to="/password-reset">
                    <p class="text-white">
                        Ho dimenticato la mia password!
                    </p>
                </router-link>
                <button type="submit" class="btn btn-custom mt-4">Login</button>
            </form>
            <router-link to="/register">
                <p class="text-white">
                    Non hai un account? <i>Registrati ora</i>
                </p>
            </router-link>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            errorMessage: ''
        };
    },
    methods: {
        async login() {
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.email,
                        password: this.password,
                    }),
                });

                if (!response.ok) {
                    this.errorMessage = 'Credenziali non valide. Riprova.';
                    return;
                }

                const data = await response.json();

                localStorage.setItem('userEmail', this.email);
                localStorage.setItem('userToken', data.token);
                localStorage.setItem('tokenExpiration',data.expiresIn);

                const productId = this.$route.query.productId;
                console.log("Going to account page")
                this.$router.push({ name: 'account', query: { productId } });
            } catch (error) {
                console.error('Login failed:', error);
                this.errorMessage = 'Si è verificato un errore durante il login. Riprova più tardi.';
            }
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.login-page {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #292D7D;
    color: white;
}

.logo {
    max-width: 200px;  /* Adjust this value as needed */
    margin-bottom: 20px;
}

.text-danger {
    color: red;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    font-size: 1.2rem;
}

.btn-custom:hover {
    background-color: #cc2e31;
    color: white;
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0.085);
    border: 2px solid;
    border-color: white;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}
</style>